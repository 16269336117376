import { NameInput } from "components/components/forms/text";
import { MessageModal } from "components/components/modals/modals";
import QUDOServer from "helpers/QUDOServerConnection";
import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";

export default function LinkedAccountsForm({ info, fetchNewInfo }) {

    const dispatch = useDispatch();

    const [steamId, setSteamId] = useState('');
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const [telegramUser, setTelegramUser] = useState();

    const form = useRef(null);

    useEffect(() => {
        if (info.linkedAccounts?.steam) {
            setSteamId(info.linkedAccounts.steam);
        }
    }, []);

    useEffect(() => {
        if (telegramUser) {
            setLoading(true);
            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/addtelegram`,
                { telegramUser: telegramUser },
                { withCredentials: true })
                .then(data => {
                    setModal(true);
                })
                .finally(() => {
                    dispatch(actions.fetchUser())
                    setLoading(false);
                });
        }
    }, [telegramUser]);

    function updateLinkedAccounts() {
        setLoading(true);
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/profile/addlinkedaccount`,
            { steamid: steamId },
            { withCredentials: true })
            .then(data => {
                setModal(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function TelegramLogin() {
        useEffect(() => {
            // Define the function
            window.onTelegramAuth = (user) => {
                setTelegramUser(user); // {authdate: timestamp, first_name: string, hash: string, id: number, last_name: string, username: string, photo_url: string}
                console.log("Open Telegram bot link: https://t.me/qudocoin")
                //window.open('https://t.me/qudocoin', '_blank')
            };
            //setTimeout(() => {
            const botName = process.env.REACT_APP_TELEGRAM_BOT
            if (global.isDev) console.log("TelegramLogin useEffect on ", botName);
            // Create script element
            const script = document.createElement('script');
            script.src = 'https://telegram.org/js/telegram-widget.js?22';
            script.async = true;
            script.dataset.telegramLogin = botName;
            script.dataset.userpic = 'false';
            script.dataset.size = 'large';
            script.dataset.radius = '5';
            script.dataset.onauth = 'onTelegramAuth(user)';
            script.dataset.requestAccess = 'write';


            document.getElementById('telegram-login').appendChild(script);
            // Append the script to the body
            //document.body.appendChild(script);
            if (global.isDev) console.log(script);

            // Clean up on component unmount
            return () => {
                if (script) {
                    console.log("TelegramLogin useEffect cleanup")
                    document.getElementById('telegram-login').removeChild(script);
                    //document.body.removeChild(script);
                }
            };
            //}, 10);
        }, []);

        return <div id="telegram-login" style={{ marginBottom: "8px" }}></div>;
    }

    return (<>
        <Form ref={form} onSubmit={e => e.preventDefault()}>

            <div style={{ fontWeight: "bold" }}>
                Linked Accounts
            </div>

            {info &&
                <div className="mb-2">
                    Telegram Account
                    {info.telegramUser ?
                        <div className="small">
                            Your Telegram linked account is&nbsp;
                            <span className="font-weight-bold">
                                @{info.telegramUser.username}
                            </span>
                        </div>
                        :
                        <div id="telegram_support_widget">
                            <TelegramLogin />
                        </div>
                    }
                </div>
            }
            <div>
                Steam ID
                <div className="small">
                    Please provide steamID64 (Dec) (i.e. 76561197960287930)
                    <br />
                    You can find your SteamID on <a target='_blank'
                        rel='noopener noreferrer'
                        href="https://www.steamidfinder.com/">
                        SteamIDFinder
                    </a>
                </div>
            </div>
            <NameInput
                key={1}
                placeholder="Steam ID"
                style={{ height: "4.5rem" }}
                className={`edit-field col-12`}
                ignoreValidation={true}
                initial={steamId}
                set={(value) => {
                    setSteamId(value);
                }}
            />

            <div
                className={`col-12 edit-profile-save-btn ${!loading ? "" : "disabled"}`}
                onClick={updateLinkedAccounts}
            >
                Link Accounts
            </div>

        </Form>

        <MessageModal
            show={modal}
            message={'Success'}
            hide={() => {
                fetchNewInfo();
                setModal(false);
            }}
        />
    </>);
}