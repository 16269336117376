import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../actions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ReactPlayer from "react-player";

const fallBack = require("../../images/fallbackAvatar.png");
const pendingBanner = require("../../images/banners/GAME_Pending.svg");

export default function GameCardComponent({ addGame }) {
    const dispatch = useDispatch();

    const info = useSelector(state => state.info);
    const games = info.games;

    const url = "https://youtu.be/bij5WM-ns5I?si=NjVsVCGIYH3-1P87";

    const [editing, setEditing] = useState();

    const edit = (i) => {
        setEditing(i);
        window.scrollTo(0, 0);
    }

    const AddBtn = () => (
        <div className="gamedevact-add-btn">
            <button type="button"
                className="std-button-active featured-button"
                onClick={addGame} >
                <div className="text-center">
                    Add New Game
                </div>
            </button>
        </div>
    );

    return (
        <>
            {editing &&
                <Redirect push to={`${process.env.REACT_APP_GAMEDEV_EDIT_GAME}/${editing}`} />
            }
            <div className="gamedevact-component-container">
                {games.length > 0 ?
                    (
                        <>
                            {games.map(i =>
                                <div key={i.name} className="col-auto"
                                    style={i.accepted ? { cursor: "pointer" } : {}}
                                    onClick={() => i.accepted && edit(i.name)}>
                                    <div key={i.imageID}
                                        className="position-relative gamedevact-game-card">
                                        <div className="gamedevact-image-container">
                                            <img
                                                className="game-website-gamedevact-image"
                                                src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}&rand=${Date.now()}`}
                                                onError={i => i.target.src = fallBack}
                                                alt="Game"
                                            />
                                        </div>
                                        <div className="gamedevact-textblock-game w-100">
                                            <div className="gamedevact-text-bg-game">
                                                {i.display_name ? i.display_name : i.name}
                                            </div>
                                        </div>
                                        {!i.accepted && (
                                            <div className="position-absolute gamedevact-approval-card">
                                                <img src={pendingBanner} alt="Pending banner" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <AddBtn />
                        </>
                    )
                    :
                    (<div>
                        <h5 className="mt-4">
                            {"Watch the video to add your first game to QUDO"}
                        </h5>
                        <div className="gamedevact-nogames-video">
                            <ReactPlayer
                                className="react-player"
                                url={url}
                                playing={false}
                                controls={true}
                                light={true}
                                width="100%"
                                height="100%"
                                config={{
                                    youtube: {
                                        playerVars: { fs: 0 }
                                    }
                                }}
                            />
                        </div>
                        <div className="gamedevact-nogames-btn">
                            <AddBtn />
                        </div>
                    </div>)
                }
            </div>
        </>
    );
}