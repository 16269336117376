import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from 'moment-timezone';
import ReactTooltip from "react-tooltip";
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import JoinGuildBanner from "../components/community/joinGuildBanner";
import GamedevsList from "../components/community/gamedevsList";
import ProvidersList from "../components/community/providersList";
import TopGuilds from "../components/community/topGuilds";
import TopPlayers from "../components/community/topPlayers";
//import ThisWeekSlider from "../components/community/thisWeekSlider";
import GamesList from "../components/community/gamesList";
import TopStakeholders from "../components/community/topStakeholders";
import { TopRewardingGames, TopRewardingTotals } from "../components/community/topRewardingGames";
import LatestRewards from "./community/latestRewards";
import GameDetailsModal from "./components/modals/GameDetailsModal";
import GuildDetailsModal from "./components/modals/GuildDetailsModal";
import GamedevDetailsModal from "./components/modals/GamedevDetailsModal";
import ProviderDetailsModal from "./components/modals/ProviderDetailsModal";
import Footer from "./community/footer";

import * as actions from "../actions";

import useWindowSize from "../helpers/useWindowSize";
import QUDOServer from "../helpers/QUDOServerConnection";
import TopReferrals from "./community/topReferrals";
import FeaturedGame from "./community/featuredGame";
import PartnerBanner from "./community/partnerBanner";

var Highcharts = require("highcharts");

// Load module after Highcharts is loaded
require("highcharts/modules/exporting")(Highcharts);

const arrow = require("../images/definitive/icn-arrow-forward.png");
const bigLogo = require("../images/definitive/QUDO_ICON_Black.svg");

class PriceChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			chartOptions: {
				chart: {
					type: "area",
					backgroundColor: "transparent",
					height: "36px",
					margin: 1
				},
				title: {
					text: "",
				},
				subtitle: {
					align: "right",
					verticalAlign: "bottom",
				},
				legend: {
					enabled: false,
				},
				colors: [
					"#fafafa",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
				],
				xAxis: {
					categories: null,
					lineColor: "transparent",
					labels: {
						enabled: false,
						style: {
							color: "#fafafa",
						},
					},
					reversed: true,
				},
				yAxis: {
					title: {
						text: "",
					},
					gridLineColor: "transparent",
					// min: 2.6,
					// max: 3.3,
					tickInterval: 0.0001,
					opposite: true,
					labels: {
						enabled: false,
						style: {
							color: "#fafafa",
						},
					},
				},
				plotOptions: {
					area: {
						fillOpacity: 0.5,
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, "#505050"],
								[1, "#232323"],
							],
						},
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: "Price",
						data: null,
						marker: {
							radius: 3,
							fillColor: "#fbd731",
						},
					},
				],
				tooltip: {
					formatter: function () {
						return `${this.x}<br>${this.y}`;
					},
					padding: 4,
					backgroundColor: 'rgba(150,150,150,0.85)',
					borderWidth: 0,
					style: {
						fontSize: '0.6rem',
						color: '#ffffff'
					}
				}
			}
		}
	}

	componentWillMount() {
		const timezone = Moment.tz.guess();

		this.setState({ timezone: timezone });
	}

	componentDidMount() {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/history/prices/TLOS?count=5`)
			.then((result) => {

				const values = result.data.map(element => Number(element.price.toFixed(4)));
				const minValue = Math.min(...values);
				const maxValue = Math.max(...values);
				const coin = result.data[0] ? result.data[0].coin : 'TLOS';

				this.setState({
					chartOptions: {
						xAxis: {
							categories: result.data.map((element) => {
								return Moment(new Date(element.timestamp)).tz(this.state.timezone).format("DD MMM");
							})
						},
						yAxis: {
							min: minValue,
							max: maxValue,
						},
						series: [{
							data: values
						}],
						tooltip: {
							formatter: function () {
								return `${this.x}<br><b>${this.y} ${coin}</b>`;
							}
						}
					}
				});
			});
	}

	render() {
		const { chartOptions } = this.state;

		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
			/>
		);
	}
}

function CommunityContent({ gamesDetailsModal, gamedevsDetailsModal, providerDetailsModal }) {
	const dispatch = useDispatch();

	const games = useSelector(state => state.games);

	const [featuredGame, setFeaturedGame] = useState('');

	useEffect(() => {
		if (!games) {
			dispatch(actions.getGames());
		}
	}, []);

	if (games) {
		return (
			<div className="community-background">
				<FeaturedGame showDetails={gamesDetailsModal} setFeaturedGame={setFeaturedGame} />
				<GamesList
					games={games}
					featuredGame={featuredGame}
					showDetails={gamesDetailsModal}
				/>
				<PartnerBanner />
				<JoinGuildBanner />
				<TopRewardingGames />
				<TopGuilds />
				<GamedevsList
					games={games}
					showDetails={gamedevsDetailsModal}
				/>
				<hr className="main-hr my-0" />
				<TopPlayers />
				<ProvidersList
					showDetails={providerDetailsModal}
				/>
				<TopReferrals />
				<TopStakeholders />
				<LatestRewards />
				<Footer />
			</div>
		);
	} else {
		return null;
	}
}

export function Header({ hideAirdrop, TLOSPrice, lastBlockReward }) {
	const windowSize = useWindowSize();
	const history = useHistory();

	const info = useSelector(state => state.info);


	return (
		<>
			{!hideAirdrop && (
				<div
					className="row mx-0"
					style={{ overflow: 'hidden', backgroundColor: '#fcc404' }}
				>
					<div
						className="col-12 px-5 py-1"
						style={{ color: "#ffffff", backgroundColor: "#1867ff" }} >
						<div className="row airdrop-banner">
							{info ?
								<div
									className="col-auto my-1 enable-click"
									onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
								>
									Do not miss out on QUDO's Mainnet Launch Airdrop
								</div>
								:
								<div
									className="col-auto my-1 enable-click"
									onClick={() => history.push("?register=true")}
								>
									Register now to not miss out on QUDO's Mainnet Launch Airdrop
								</div>
							}
							<div
								className="col-auto my-1 enable-click"
								onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
								style={{ fontWeight: "bold" }} >
								<div className="d-inline-block" >
									Know more
									<div className="d-inline-block ml-2">
										<FontAwesomeIcon icon={faArrowRight} />
									</div>
								</div>
							</div>
						</div>
					</div>
					{TLOSPrice &&
						<div
							className={`row col-12 px-5 py-2 align-items-center justify-content-between`}
							id={`${windowSize.width >= 991 ? '' : 'telos-now-mobile-padding'}`}
							style={{ backgroundColor: "#282725" }} >

							<div className="row col-6 col-sm-5 col-xl-5 align-items-center">
								<div className="" id="qudo-now-title">
									{windowSize.width >= 470 ? 'TELOS' : 'TLOS'} NOW
								</div>
								<div className="qudo-now-text-sm mx-2">
									{TLOSPrice}
									<span>USD
										{/* TLOS price */}
									</span>
								</div>

								{windowSize.width >= 1472 &&
									<div className="col-2">
										<PriceChart />
									</div>
								}

							</div>

							{windowSize.width >= 1232 &&
								<div className="row col-auto mr-auto">
									<TopRewardingTotals />
								</div>
							}

							<div className={`col-6 col-sm-6 col-md-auto`}>
								<div className="row justify-content-end" style={{ textAlign: "right" }}>
									<div className="col-auto qudo-now-text-sm qudo-now-block">
										Current<br />Block Reward
									</div>
									{lastBlockReward && (
										<div
											data-tip
											data-for='BlockReward'
											className="col-auto col-md-auto qudo-now-text-huge enable-click"
										>
											{Number(String(lastBlockReward).split(' ')[0]).toFixed(0)}{" "}
											<div className="d-inline-block qudo-now-text"
												style={{ color: "#fcd902" }}>
												QUDO/min
											</div>
											<ReactTooltip id='BlockReward' type='light'>
												<div>This is the amount of QUDO tokens that are minted in each</div>
												<div>block (every minute) and then shared between all stakeholders.</div>
											</ReactTooltip>
										</div>
									)}
								</div>
							</div>

						</div>
					}
				</div>
			)}
		</>
	);
}

export default function Community() {
	const history = useHistory();
	const dispatch = useDispatch();
	const windowSize = useWindowSize();

	const config = useSelector(state => state.config);
	const lastBlockReward = useSelector(state => state.block.lastBlockReward);

	const [TLOSPrice, setTLOSPrice] = useState(null);

	const [descriptionDetailsModalGame, setDescriptionDetailsModalGame] = useState(null);
	const [descriptionDetailsModalGuild, setDescriptionDetailsModalGuild] = useState(null);
	const [descriptionDetailsModalGamedev, setDescriptionDetailsModalGamedev] = useState(null);
	const [descriptionDetailsModalProvider, setDescriptionDetailsModalProvider] = useState(null);

	//const smallScreen = windowSize && windowSize.width < 435;
	const smallScreen = windowSize && windowSize.width < 576;

	useEffect(() => {
		dispatch(actions.getBlock());

		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/history/prices/TLOS?count=1`)
			.then((result) => {
				const originalPrice = parseFloat(result.data[0].price);
				const price = originalPrice < 0.1 ? originalPrice.toFixed(3) : originalPrice.toFixed(2);

				setTLOSPrice(price);
			});

		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		//console.log(url, params);

		const gameName = params.get('game');
		const guildName = params.get('guild');

		if (gameName) {
			setDescriptionDetailsModalGame(gameName);
		} else if (guildName) {
			setDescriptionDetailsModalGuild(guildName);
		}
	}, []);

	useEffect(() => {
		if (descriptionDetailsModalGame) {
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);

			const gameName = params.get('game');

			if (!gameName) {
				history.push('?game=' + descriptionDetailsModalGame);
			}
		} else {
			document.title = 'QUDO Games';
		}
	}, [descriptionDetailsModalGame]);

	useEffect(() => {
		if (descriptionDetailsModalGuild) {
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);

			const guildName = params.get('guild');

			if (!guildName) {
				history.push('?guild=' + descriptionDetailsModalGuild);
			}
		}
	}, [descriptionDetailsModalGuild]);

	return (
		<div className="row">
			<div className="left-container order-1" style={{ overflow: "auto" }}>
				<Header TLOSPrice={TLOSPrice} lastBlockReward={lastBlockReward} />
			</div>

			<div className={`left-container justify-content-center position-relative ${windowSize && windowSize.width < 992 ? "order-3" : "order-2"}`}>
				<CommunityContent
					gamesDetailsModal={setDescriptionDetailsModalGame}
					gamedevsDetailsModal={setDescriptionDetailsModalGamedev}
					providerDetailsModal={setDescriptionDetailsModalProvider}
				/>
			</div>

			<GameDetailsModal
				show={!!descriptionDetailsModalGame}
				setShow={setDescriptionDetailsModalGame}
				game={descriptionDetailsModalGame}
			/>
			<GamedevDetailsModal
				show={!!descriptionDetailsModalGamedev}
				setShow={setDescriptionDetailsModalGamedev}
				gamedev={descriptionDetailsModalGamedev}
			/>
			<ProviderDetailsModal
				show={!!descriptionDetailsModalProvider}
				setShow={setDescriptionDetailsModalProvider}
				provider={descriptionDetailsModalProvider}
			/>
			{descriptionDetailsModalGuild && (
				<GuildDetailsModal
					show={!!descriptionDetailsModalGuild}
					hide={setDescriptionDetailsModalGuild}
					guild={descriptionDetailsModalGuild}
				/>
			)}
		</div>
	);
}
