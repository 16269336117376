import QUDOServer from "helpers/QUDOServerConnection";
import useWindowSize from "helpers/useWindowSize";
import React, { useState, useEffect } from "react";
import StarRating from 'react-star-ratings';

export default function FeaturedGame({ showDetails, setFeaturedGame }) {

    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 992);

    const [game, setGame] = useState();

    useEffect(() => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/game/featured/get`)
            .then((result) => {
                setGame(result.data);
                setFeaturedGame(result.data.name);
            }).catch((err) => {

            });
    }, []);

    const play = () => {
        showDetails(game.name);
    }

    return (
        <>
            {game ?
                <>
                    <div className="videoContainer" >
                        <video preload="metadata" controls={false} autoPlay loop muted
                            disablePictureInPicture playsInline about="Gameplay from QUDO's featured game!">
                            <source src={game.videoURL} type="video/mp4" />
                        </video>
                    </div>

                    <div className={`px-5 featured-game ${!isMobile ? 'pt-5' : 'pt-4'}`}>
                        <div className="featured-title">
                            FEATURED TODAY
                        </div>
                        <div className="featured-game-title">
                            {game.display_name ? game.display_name : game.name}
                        </div>
                        <div className="featured-game-tag">
                            {game.genres[0] ? game.genres[0].name : ''}
                        </div>

                        <button type="button"
                            className="std-button-active featured-button"
                            onClick={play} >
                            <div className="text-center">
                                PLAY
                            </div>
                        </button>

                        {
                            game.reviewNr > 5 &&
                            <>
                                <div className="featured-game-rate">
                                    GAME RATE
                                </div>

                                <div className="row rating w-100 col-12">
                                    <div className="featured-rating-number">
                                        {Number(game.rating).toFixed(1)}
                                    </div>
                                    <div className="stars">
                                        <StarRating
                                            rating={game.rating}
                                            starRatedColor="#fcd902"
                                            name="rating"
                                            numberOfStars={5}
                                            starDimension={!isMobile ? '1.2rem' : '1rem'}
                                            starSpacing="2px"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </>
                :
                <div></div>
            }
        </>
    )
}