import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";

const moment = require("moment-timezone");

const fallBackAvatar = require("../../images/definitive/icon-avatar.png");

export default function LatestRewards() {
	const [showAll, setShowAll] = useState(true);

	const dispatch = useDispatch();

	const defaultRowNr = 3;

	const block = useSelector(state => state.block);

	useEffect(() => {
		setInterval(() => {
			dispatch(actions.getBlock());
		}, 45 * 1000);
	}, []);

	useEffect(() => {
		setShowAll(false);
	}, [block]);

	let games = {};
	if (block.lastBlock) {
		block.lastBlock.forEach(player => {
			player.games.forEach(game => {
				if (games[game.name]) {
					games[game.name] = {
						name: game.name,
						gameName: game.display_name ?
							game.display_name : game.name,
						imageURL: game.imageURL,
						players: [
							...games[game.name].players,
							{
								name: player.display_name ?
									player.display_name :
									(player.username ? player.username : player.account_name),
								imageID: player.imageID,
								imageURL: player.imageURL
							}
						]
					}
				} else {
					games[game.name] = {
						name: game.name,
						gameName: game.display_name ?
							game.display_name : game.name,
						imageURL: game.imageURL,
						players: [{
							name: player.display_name ?
								player.display_name :
								(player.username ? player.username : player.account_name),
							imageID: player.imageID,
							imageURL: player.imageURL
						}]
					}
				}
			})
		});
	}

	const gameKeys = Object.keys(games).slice(0, (showAll ? Object.keys(games).length : defaultRowNr));

	return (

		<div id="lb-container">
			<>
				<div style={{ color: "white" }} id="lb-title-box">
					<div className="lb-title">
						Last Block Rewards
					</div>
				</div>

				<div
					id="lb-inner-container"
					className="row"
					style={{ clear: "both" }}
				>
					{(games && Object.keys(games).length > 0) && (
						<div className="col-12 col-xl-8">
							<div
								className="border-bottom-0 row"
								style={{ marginTop: "2rem" }}
							>
								<div className="col-3 col-lg-2"></div>
								<div className="col-4 col-lg-4 lb-username-header">
									User
								</div>
								<div className="col-4 col-lg-4 text-right lb-activity-header">
									{/* Activity */}
									Games
								</div>
							</div>

							{gameKeys.map((gameKey, index) => {
								const game = games[gameKey];

								return (
									<div
										key={game.gameName}
										className="border-bottom row"
										style={{
											alignItems: "center",
											minHeight: "5rem"
										}}
									>
										{Array.isArray(game.players) &&
											(
												<>
													<div className="col-3 col-lg-2">
														<img
															alt="User avatar"
															src={
																game.players[0].imageURL ? game.players[0].imageURL :
																`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${game.players[0].imageID ? game.players[0].imageID : '0'}&size=xS`
															}
															className="rounded-circle d-inline-block"
															style={{ height: "3rem" }}
															onError={(i) => {
																game.players[0].target.src = fallBackAvatar;
															}}
														/>
														{game.players.length === 2 ?
															<img
																alt="User avatar 2"
																src={
																	game.players[1].imageURL ? game.players[1].imageURL :
																	`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${game.players[1].imageID ? game.players[1].imageID : '0'}&size=xS`
																}
																className="rounded-circle d-inline-block"
																style={{ height: "3rem", position: 'absolute', left: '2rem' }}
																onError={(i) => {
																	game.players[1].target.src = fallBackAvatar;
																}}
															/>
															:
															game.players.length >= 3 &&
															(
																<>
																	<img
																		alt="User avatar 2"
																		src={
																			game.players[1].imageURL ? game.players[1].imageURL :
																			`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=${game.players[1].imageID ? game.players[1].imageID : '0'}&size=xS`
																		}
																		className="rounded-circle d-inline-block"
																		style={{ height: "3rem", position: 'absolute', left: '2rem' }}
																		onError={(i) => {
																			game.players[1].target.src = fallBackAvatar;
																		}}
																	/>
																	<img
																		alt="User avatar 3"
																		src={`${process.env.REACT_APP_QUDO_SERVER}/api/avatars/getbyid?imageID=0&size=xS`}
																		className="rounded-circle d-inline-block"
																		style={{ height: "3rem", position: 'absolute', left: '3rem' }}
																		onError={(i) => {
																			game.players[0].target.src = fallBackAvatar;
																		}}
																	/>
																</>
															)
														}
													</div>
													<div className="col-4 col-lg-4 lb-username">
														{game.players.length === 1 ?
															game.players[0].name
															:
															game.players.length === 2 ?
																(game.players[0].name + ' and ' + game.players[1].name)
																:
																game.players.length === 3 ?
																	(game.players[0].name + ', ' + game.players[1].name + ' and ' + (game.players.length - 2) + ' other')
																	:
																	(game.players[0].name + ', ' + game.players[1].name + ' and ' + (game.players.length - 2) + ' others')
														}
													</div>
												</>
											)
										}
										<div className="col-4 col-lg-6 text-right lb-activity">
											<>
												<div
													key={game.gameName}
													className="mb-2"
													style={{ marginRight: '-5px' }}
												>
													{game.gameName}

													<img
														alt={game.gameName}
														src={
															game.imageURL ? game.imageURL :
															`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${game.name}`
														}
														className="rounded-circle d-inline-block ml-2"
														style={{ width: '3rem', height: '3rem' }}
													/>
												</div>
											</>
										</div>
									</div>
								);
							})}

							{(Object.keys(games).length > defaultRowNr) && (
								<div
									className="mt-2 py-3 text-center enable-click"
									onClick={() => {
										setShowAll(!showAll);
									}}
								>
									<h5>{showAll ? "Show Less" : "Show All"}</h5>
								</div>
							)}
						</div>
					)}

					{block && (
						<div
							className={`${block.lastBlock.length !== 0
								? "col-xl-4"
								: "col-lg-12 col-xl-12"
								} col-12 position-relative d-flex flex-column justify-content-center`}
							style={{ alignItems: "center" }}
						>
							<div className="lb-total-amount-title">
								TOTAL AMOUNT OF REWARDS
							</div>
							<div className="lb-total-amount">
								{Number(String(block.lastBlockReward).split(' ')[0]).toFixed(0)}

								<div className="d-inline-block lb-total-amount-title ml-1">
									{" "}
									qudo
								</div>
							</div>
						</div>
					)}
				</div>
			</>
		</div>
	);
}

